<template>
  <section id="container" class="efs_ds">
    <div  class="box_flex mgb10">
      <div class="w10 f_l">
        <kendo-datepicker
            ref="searchDtRef"
            class="w100" 
            v-model="searchDate"
            :format="'yyyy/MM/dd'"
            :placeholder="'년/월/일'"
        ></kendo-datepicker>
        <!-- <button @click="[allApiCall(), getOutCompleteDateCnt()]" class="mid_btn orange">새로고침</button> -->
      </div>
        <div class="w90">
          <!-- <h1 class="user_txt w100">{{loginUserData.cmpyNm}} {{ loginUserData.userNm }}님 환영합니다.</h1> -->
           <multi-select-company
                  :class="'w20 mgr5 efs_dsb_company f_l'"
                  :props-cmpy-cd-data-array="selectedCmpyCdList"
                  @on-change-multi-dropdown-company="onChangeMultiDropdownCompany"
              ></multi-select-company>
            <div class="w80" style="margin-left:20%">
              <ul class="list_company mgt0">
                <li v-for="item in selectedCmpyCdItemList">{{item.cmpyNm}}<span class="k-icon k-i-close" @click="deleteSelectedCmpyCd(item.cmpyCd)"></span></li>
              </ul>
            </div>
        </div>
    </div>
    <div class="efs_dashbd box_flex">
      <div class="left box_flex_row w50">
        <div class="box_bg top">
          <div class="box_flex">
            <div class="w50">
              <common-button-group
                  :class="'w40 f_r'"
                  :button-group-class="'tab'"
                  :button-active-class="'on'"
                  :button-class="null"
                  v-model="inType"
                  :default-value="constant.inReq"
                  :data-items="[{text: '신품입고', value: 'inNew',},{text: '회수신청', value: 'inReq',}]"
              ></common-button-group>
              <h2>입고</h2>
              <div class="box_flex_row w100">
                <div>
                  <ol class="receiving">
                    <li class="db_count">
                      <div class="graph">
                        <div class="box_graph mgr5 mgt20">
                          <div class="inner-content">
                            <span class="s_txt">완료</span>
                            <span class="txt_per">{{ inDayPercentage }}%</span>
                          </div>
                          <kendo-arcgauge ref="inDayArcgaugeRef"
                                          :scale-start-angle="90"
                                          :scale-end-angle="455"
                                          :scale-range-size="25"
                                          :scale-min="0"
                                          :transitions="false"
                                          :value="inDayPercentage"
                                          :scale-max="100"
                                          :gauge-area="{ width: 130, height: 130, margin :{ top: 0 }}"
                          >
                            <kendo-arcgauge-color :color="'#4b79d8'"></kendo-arcgauge-color>
                          </kendo-arcgauge>
                        </div>
                      </div>

                      <div class="title">당일</div>
                      <dl>
                        <dd class="txt">{{ inType === 'inReq' ? constant.req : constant.expect }}</dd>
                        <dt @click="inType === 'inReq' ? goInNewOrReqList(constant.day, constant.req) : ''"
                            :class="`${inType === 'inReq' && possibleLink ? 'link' : ''} num green`">
                          {{ inCnt[inType].expect | comma}}
                        </dt>
                      </dl>
                      <dl>
                        <dd class="txt">완료</dd>
                        <dt @click="goInNewOrReqList(constant.day, constant.complete)"
                            :class="`${possibleLink ? 'link' : ''} num blue`">{{ inCnt[inType].complete | comma}}</dt>
                      </dl>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol class="receiving">
                    <li class="db_count">
                      <div class="graph">
                        <div class="box_graph mgt20">
                          <div class="inner-content">
                            <span class="s_txt">완료</span>
                            <span class="txt_per">{{ inMonthPercentage }}%</span>
                          </div>
                          <kendo-arcgauge ref="inMonthArcgaugeRef"
                                          :scale-start-angle="90"
                                          :scale-end-angle="455"
                                          :scale-range-size="25"
                                          :scale-min="0"
                                          :transitions="false"
                                          :value="inMonthPercentage"
                                          :scale-max="100"
                                          :gauge-area="{ width: 130, height: 130, margin :{ top: 0 }}"
                          >
                            <kendo-arcgauge-color :color="'#4b79d8'"></kendo-arcgauge-color>
                          </kendo-arcgauge>
                        </div>
                      </div>
                      <div class="title w100">월간누적[{{ convertTwoDigit(searchDate.getMonth()+1) }}/01~ {{ convertTwoDigit(searchDate.getMonth()+1) }}/{{ convertTwoDigit(searchDate.getDate()) }}]</div>
                      <dl>
                        <dd class="txt">{{ inType === 'inReq' ? constant.req : constant.expect }}</dd>
                        <dt @click="inType === 'inReq' ? goInNewOrReqList(constant.month, constant.req) : ''"
                            :class="`${inType === 'inReq' && possibleLink ? 'link' : '' } num green`">{{ inCnt[inType].expectMonth | comma}}</dt>
                      </dl>
                      <dl>
                        <dd class="txt">완료</dd>
                        <dt @click="goInNewOrReqList(constant.month, constant.complete)" :class="`${possibleLink ? 'link' : ''} num blue`">{{ inCnt[inType].completeMonth | comma}}</dt>
                      </dl>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="efs_calenda w35 mgl10">
              <span @click="$router.push({name:'OrdManageCalendar'})" class="txt_link f_r">더보기</span>
                <h2>발주캘린더</h2>
              <div>
                <v-date-picker
                    v-model="selectedCalendarDate"
                    :attributes="calendarAttrs"
                    :class="'w100'"
                    :style="'height:100%;'"
                    @update:from-page="onChangeMonthYear"
                >
                </v-date-picker>
                 <ul class="calenda_state mgt10"> 
                <li v-for="item in constant.ordStatusCdList"><span class="mgr10" :style="`color : ${item.color};`">■</span>{{ item.ordStatusNm }} </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="box_bg bottom mgt10">
          <button
              v-if="possibleLink"
              @click="onClickInspExcelDownload" class="mid_btn f_r"><i class="download_icon"></i>엑셀 다운로드</button>
          <h2>검품</h2>
          <div>
            <ol class="inspection mgt10">
              <li>
                <div class="txt">체험</div>
                <div class="num">{{ inspCnt.expCnt | comma}}</div>
              </li>
              <li>
                <div class="txt">교환</div>
                <div class="num">{{ inspCnt.changeCnt | comma}}</div>
              </li>
              <li>
                <div class="txt">A/S신청</div>
                <div class="num">{{ inspCnt.asCnt | comma}}</div>
              </li>
              <li>
                <div class="txt">대여</div>
                <div class="num">{{ inspCnt.rentCnt | comma}}</div>
              </li>
              <li>
                <div class="txt">전환</div>
                <div class="num">{{ inspCnt.trnsCnt | comma}}</div>
              </li>
              <li>
                <div class="txt">환불</div>
                <div class="num">{{ inspCnt.refundCnt | comma}}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div class="center w30 box_bg">
        <h2>출고</h2>
        <div class="box_flex_row" style="overflow:hidden;">
          <div>
            <common-button-group
                :class="'w100'"
                :button-group-class="'tab'"
                :button-active-class="'on'"
                :button-class="null"
                v-model="outSetGbnType"
                :data-items="$store.state.codeRequestStore.search.combobox.setGbn.data"
                :data-text-field="'cdNm'"
                :data-value-field="'cdId'"
            ></common-button-group>
            <ol class="receiving">
              <li class="db_count">
                <div class="graph">
                  <div class="box_graph">
                    <div class="inner-content">
                      <span class="s_txt">완료</span>
                      <span class="txt_per">{{ outDayPercentage }}%</span>
                    </div>
                    <kendo-arcgauge ref="outDayArcgaugeRef"
                                    :scale-start-angle="90"
                                    :scale-end-angle="460"
                                    :scale-range-size="25"
                                    :minor-unit="5"
                                    :scale-min="0"
                                    :transitions="true"
                                    :value="outDayPercentage"
                                    :scale-max="100"
                                    :gauge-area="{ width: 130, height: 125, margin :{ top: 0 }}"
                    >
                      <kendo-arcgauge-color :color="'#4b79d8'"></kendo-arcgauge-color>
                    </kendo-arcgauge>
                  </div>
                </div>
                <div class="title">당일</div>
                <dl>
                  <dd class="txt">신청</dd>
                  <dt @click="possibleLink ? goOutReqList(constant.day,constant.req): ''" :class="`${possibleLink ? 'link' : ''} num green`">{{ outCnt[outSetGbnKey].reqCnt | comma}}</dt>
                </dl>
                <dl>
                  <dd class="txt">완료</dd>
                  <dt @click="possibleLink ? goOutReqList(constant.day,constant.complete) : ''" :class="`${possibleLink ? 'link' : ''} num blue`">{{ outCnt[outSetGbnKey].completeCnt | comma}}</dt>
                </dl>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li class="db_count">
                <div class="graph">
                  <div class="box_graph">
                    <div class="inner-content" >
                      <span class="s_txt">완료</span>
                      <span class="txt_per">{{ outMonthPercentage }}%</span>
                    </div>
                    <kendo-arcgauge ref="outMonthArcgaugeRef"
                                    :scale-start-angle="90"
                                    :scale-end-angle="460"
                                    :scale-range-size="25"
                                    :minor-unit="5"
                                    :scale-min="0"
                                    :transitions="true"
                                    :value="outMonthPercentage"
                                    :scale-max="100"
                                    :gauge-area="{ width: 130, height: 125, margin :{ top: 0 }}"
                    >
                      <kendo-arcgauge-color :color="'#4b79d8'"></kendo-arcgauge-color>

                    </kendo-arcgauge>
                  </div>
                </div>
                <div class="title">월간누적 [{{ convertTwoDigit(searchDate.getMonth()+1) }}/01~ {{ convertTwoDigit(searchDate.getMonth()+1) }}/{{ convertTwoDigit(searchDate.getDate()) }}]</div>
                <dl>
                  <dd class="txt">신청</dd>
                  <dt @click="possibleLink ? goOutReqList(constant.month, constant.req) : ''" :class="`${possibleLink ? 'link' : ''} num green`">{{ outCnt[outSetGbnKey].reqMonthCnt | comma}}</dt>
                </dl>
                <dl>
                  <dd class="txt">완료</dd>
                  <dt @click="possibleLink ? goOutReqList(constant.month, constant.complete) : ''" :class="`${possibleLink ? 'link' : ''} num blue`">{{ outCnt[outSetGbnKey].completeMonthCnt | comma }}</dt>
                </dl>
              </li>
            </ol>
          </div>
        </div>
        <h2 class="mgt10">
          <div class="f_r mgb10 w70 box_flex">
          <span class="w45">
          <kendo-datepicker
              id="start"
              class="w100"
              v-on:change="startChange"
              :value="new Date((new Date).getFullYear(), (new Date).getMonth(), 1)"
              v-model="selectedStartDate"
              :format="'yyyy/MM/dd'"
              :placeholder="'년/월/일'"
              @close="$refs.endDateRef.kendoWidget().open()"
          ></kendo-datepicker></span>
            ~
            <span class="w45">
          <kendo-datepicker
              ref="endDateRef"
              id="end"
              class="w100"
              v-on:change="endChange"
              :value="new Date()"
              :max="new Date((selectedStartDate).getFullYear(), (selectedStartDate).getMonth(), (selectedStartDate).getDate()+maxDate)"
              v-model="selectedEndDate"
              :format="'yyyy/MM/dd'"
              :placeholder="'년/월/일'"
          ></kendo-datepicker></span>
          </div>
          기간별 건수
        </h2>
        <common-button-group
            :class="'w100'"
            :button-group-class="'tab'"
            :button-active-class="'on'"
            :button-class="null"
            v-model="searchOutType"
            :data-items="[{text: '전체', value: ''},{text: '택배출고', value: '01'},{text: '차량/기타 출고', value: '02'}]"
        ></common-button-group>

        <div class="w100" style="background: #fbfbfb;">
          <kendo-chart :render-as="'canvas'"
                       :chart-area="{background : '', height:180}"
                       :data-source="outChartDataSource"
                       :series="[{
                          type: 'line',
                          axis: 'out',
                          name: '출고',
                          field : 'outCnt',
                          categoryField: 'category',
                          color: '#fe5f40',
                        }]"
                       :category-axis="{
                         justified: true,
                       }"
                       :value-axis="[{
                         name: 'out',
                         max : outChartMaxCnt,
                         majorUnit : outChartMajorUnit,
                         labels : { template : `#= kendo.format('{0:N0}', value) # 건 `}
                       }]"
                       :tooltip="{
                         visible: true,
                         background : '#ffffff',
                         template : `#= dataItem.MMdd # : #= kendo.format('{0:N0}', value) #건 &nbsp`
                       }"
                       :legend-visible="false"
                       :theme="'sass'">
          </kendo-chart>
        </div>
      </div>
      <div class="w20 box_flex_row">
        <div class="top box_bg">
          <span @click="$router.push({name:'BoardList'})" class="txt_link f_r">더보기</span>
          <h2>공지사항</h2>
          <div v-if="boardList.length > 0">
            <ol class="notification box_flex_row">
              <li v-for="data in boardList"
                  @click="$router.push({name : 'BoardDetail', params : { boardId : data.boardId }})"
                  style="cursor: pointer"
              >
                <span class="date">{{ data.frstRgstDt }}</span>
                <span :class="'state ' + (data.boardGbn === '03' ? 'blue' : data.boardGbn === '02' ? 'orange' : '')" >{{data.boardGbnNm}}</span>
                <span class="title">{{ data.title }}</span>
              </li>
            </ol>
          </div>
          <div v-else class="notification">
            등록된 공지사항이 없습니다.
          </div>
        </div>
        <div class="mgt10 bottom box_bg">
          <h2>배송</h2>
          <common-button-group
              :class="'w100'"
              :button-group-class="'tab'"
              :button-active-class="'on'"
              :button-class="null"
              v-model="delvType"
              :data-items="$store.state.codeRequestStore.search.combobox.setGbn.data"
              :data-text-field="'cdNm'"
              :data-value-field="'cdId'"
          ></common-button-group>

          <table
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              class="main_tb"
          >
            <colgroup>
              <col width="w50" />
              <col width="w20" />
              <col width="w20" />
            </colgroup>
            <tbody>
            <tr>
              <th scope="col"></th>
              <th scope="col">출고</th>
              <th scope="col">회수</th>
            </tr>
            <tr name="no-mode-fade">
              <td>배송준비중</td>
              <td @click="possibleLink ? goTrackingList('01', 'OUT') : ''" :class="`txt_c num blue ${possibleLink ? 'link' : ''}`">{{ delvCnt[delvSetGbnKey].outPendingCnt | comma }}</td>
              <td @click="possibleLink ? goTrackingList('01', 'IN') : ''" :class="`txt_c num blue ${possibleLink ? 'link' : ''}`">{{ delvCnt[delvSetGbnKey].inPendingCnt | comma }}</td>
            </tr>
            <tr>
              <td>배송중</td>
              <td @click="possibleLink ? goTrackingList('02', 'OUT') : ''" :class="`txt_c num blue ${possibleLink ? 'link' : ''}`">{{ delvCnt[delvSetGbnKey].outActiveCnt | comma }}</td>
              <td @click="possibleLink ? goTrackingList('02', 'IN') : ''" :class="`txt_c num blue ${possibleLink ? 'link' : ''}`">{{ delvCnt[delvSetGbnKey].inActiveCnt | comma }}</td>
            </tr>
            <tr>
              <td>완료</td>
              <td @click="possibleLink ? goTrackingList('03', 'OUT') : ''" :class="`txt_c num blue ${possibleLink ? 'link' : ''}`">{{ delvCnt[delvSetGbnKey].outCompleteCnt | comma }}</td>
              <td @click="possibleLink ? goTrackingList('03', 'IN') : ''" :class="`txt_c num blue ${possibleLink ? 'link' : ''}`">{{ delvCnt[delvSetGbnKey].inCompleteCnt | comma }}</td>
            </tr>
            <tr>
              <td>이상</td>
              <td @click="possibleLink ? openDelvAbnormalWindow('OUT'): ''" :class="`txt_c num blue ${possibleLink ? 'link' : ''}`">{{ delvCnt[delvSetGbnKey].outAbnormalCnt | comma }}</td>
              <td @click="possibleLink ? openDelvAbnormalWindow('IN'): ''" :class="`txt_c num blue ${possibleLink ? 'link' : ''}`">{{ delvCnt[delvSetGbnKey].inAbnormalCnt | comma }}</td>
            </tr>
            </tbody>
          </table>
          <div class="mgt10">*상태이상: 적용일자 무관, 3일이상 체류</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import { mapGetters } from 'vuex'
import MultiSelectCompany from '@/components/MultiSelectCompany'

export default {
  name: 'DashBoard',
  components: {
    MultiSelectCompany,
  },
  computed : {
    ...mapGetters(['loginUserData']),
    possibleLink : function (){
      return false
      // const vm = this
      // let result = false
      // if(!(['05', '25', '35'].includes(vm.loginUserData.authCd))) {
      //   result = true
      // }
      // return result
    },
    outSetGbnKey : function (){
      return this.outSetGbnTypeMap[this.outSetGbnType]
    },
    delvSetGbnKey : function () {
      return this.delvTypeMap[this.delvType]
    },
    inDayPercentage : function (){
      const vm = this
      let inCnt = vm.inCnt[vm.inType]
      return vm.getPercentage(inCnt.complete ,inCnt.expect)
    },
    inMonthPercentage : function (){
      const vm = this
      let inCnt = vm.inCnt[vm.inType]
      return vm.getPercentage(inCnt.completeMonth ,inCnt.expectMonth)
    },
    outDayPercentage : function (){
      const vm = this
      let outCnt = vm.outCnt[vm.outSetGbnTypeMap[vm.outSetGbnType]]
      return vm.getPercentage(outCnt.completeCnt ,outCnt.reqCnt)
    },
    outMonthPercentage : function (){
      const vm = this
      let outCnt = vm.outCnt[vm.outSetGbnTypeMap[vm.outSetGbnType]]
      return vm.getPercentage(outCnt.completeMonthCnt ,outCnt.reqMonthCnt)
    },
  },
  watch : {
    searchDate : function(newValue, oldValue){
      const vm = this
      vm.searchDateMonthFirstDay = new Date(newValue.getFullYear(), newValue.getMonth(), 1)
      vm.allApiCall()
    },
    selectedStartDate : function(newValue, oldValue){
      const vm = this
      if(newValue) {
        if(newValue.getMonth() === 1) {
          vm.maxDate = 27
        }else if([4,6,9,11].includes(newValue.getMonth()+1)){
          vm.maxDate = 29
        }else {
          vm.maxDate = 30
        }
        vm.selectedEndDate = new Date(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()+vm.maxDate)
        vm.$refs.endDateRef.kendoWidget().value(vm.selectedEndDate)
        vm.getOutCompleteDateCnt()
      }
    },
    selectedEndDate : function(newValue, oldValue){
      const vm = this
      if(newValue) vm.getOutCompleteDateCnt()
    },
    searchOutType : function (newValue, oldValue){
      const vm = this
      vm.getOutCompleteDateCnt()
    },
  },
  created() {
    const vm = this
    if(vm.selectedCmpyCdList.length>0){
      vm.allApiCall()
      vm.getOutCompleteDateCnt()
    }
  },
  mounted() {
    const vm = this
    let start = kendo.jQuery("#start").data("kendoDatePicker");
    let end = kendo.jQuery("#end").data("kendoDatePicker");
    start.max(end.value());
    end.min(start.value());
  },
  methods: {
    deleteSelectedCmpyCd : function (cmpyCd){
      const vm = this

      vm.selectedCmpyCdList.forEach((item,i)=>{
        if(item === cmpyCd){
          vm.selectedCmpyCdList.splice(i,1)
        }
      })
      vm.selectedCmpyCdItemList.forEach((item,i)=>{
        if(item.cmpyCd === cmpyCd){
          vm.selectedCmpyCdItemList.splice(i,1)
        }
      })
      vm.allApiCall()
      vm.getOutCompleteDateCnt()
    },
    onChangeMultiDropdownCompany : function (e){
      const vm = this
      vm.selectedCmpyCdList = e.cmpyCd
      vm.selectedCmpyCdItemList = e.cmpyArray
      if(vm.selectedCmpyCdList?.length > 0){
        vm.allApiCall()
        vm.getOutCompleteDateCnt()
      }
    },
    startChange: function (e) {
      let start = kendo.jQuery("#start").data("kendoDatePicker");
      let end = kendo.jQuery("#end").data("kendoDatePicker");
      let startDate = start.value(),
          endDate = end.value();
      if (startDate) {
        startDate = new Date(startDate);
        startDate.setDate(startDate.getDate());
        end.min(startDate);
        end.max(new Date(startDate.getFullYear(), startDate.getMonth()+1, startDate.getDate()));
      } else if (endDate) {
        start.max(new Date(endDate));
        end.max(new Date(startDate.getFullYear(), startDate.getMonth()+1, startDate.getDate()));
      } else {
        endDate = new Date();
        start.max(endDate);
        end.min(endDate);
        end.max(new Date(startDate.getFullYear(), startDate.getMonth()+1, startDate.getDate()));
      }
    },
    endChange: function (e) {
      const vm = this
      let start = kendo.jQuery("#start").data("kendoDatePicker");
      let end = kendo.jQuery("#end").data("kendoDatePicker");
      let endDate = end.value()
      vm.selectedEndDate = endDate
      let startDate = start.value();
      if (endDate) {
        endDate = new Date(endDate);
        endDate.setDate(endDate.getDate());
        start.max(endDate);
        end.max(new Date(startDate.getFullYear(), startDate.getMonth()+1, startDate.getDate()));
      } else if (startDate) {
        end.min(new Date(startDate));
        end.max(new Date(startDate.getFullYear(), startDate.getMonth()+1, startDate.getDate()));
      } else {
        endDate = new Date();
        start.max(endDate);
        end.min(endDate);
        end.max(new Date(startDate.getFullYear(), startDate.getMonth()+1, startDate.getDate()));
      }
    },
    getPercentage(completeMonthCnt, reqCnt){
      if( completeMonthCnt === 0 ) return 0
      else if( completeMonthCnt !== 0 && reqCnt === 0) return 100

      let result = completeMonthCnt / reqCnt * 100
      return result > 100 ? 100 : Number(result.toFixed(1))
    },
    allApiCall : function (){
      const vm = this
      vm.getInCnt()
      vm.getInspCnt()
      vm.getBoardList()
      vm.getDelvCnt()
      vm.getOutCnt()
      vm.getOrdCalender()
    },
    goInNewOrReqList : function (dateType, completeType){
      const vm = this

      if(!vm.possibleLink){ return false }

      let routeName = ''
      if(vm.inType === 'inReq') routeName = 'InReqList'
      else if(vm.inType === 'inNew') routeName = 'InNewList'

      if(!routeName) {
        console.error('router name is empty')
        return false
      }

      let param
      let searchDate = {
        start : dateType === vm.constant.month ? vm.searchDateMonthFirstDay : vm.searchDate,
        end : vm.searchDate
      }

      switch (routeName){
        case 'InNewList':
          param = { searchDate }
          break
        case 'InReqList' :
          if(completeType === vm.constant.req) {
            param = { searchDate }
          }else if(completeType === vm.constant.complete){
            param = {
              searchCompleteDate : searchDate,
              inStatus : vm.constant.inStatus.complete,
            }
          }
          break
      }

      vm.$router.push({
        name: routeName,
        params : param
      })
    },
    goStkItemList : function (renewGbnType){
      const vm = this
      vm.$router.push({
        name: 'StkItemList',
        params : {
          renewGbn : renewGbnType,
          itemCd : vm.$refs.itemCdRef.widget().value()
        }
      })
    },
    goPrcsList : function (renewGbnType){
      const vm = this
      vm.$router.push({
        name: 'ProcessHistoryList',
        params : {
          renewGbn : renewGbnType,
          prcsDepth2 : "0102",
          searchDate : {
            start : vm.searchDate,
            end : vm.searchDate
          }
        }
      })
    },
    onChangeDelvAbnormal (ev) {
      let rowData = ev.sender.dataItem(ev.sender.select())
      let routerName, params
      if(rowData.outType){
        routerName = 'OutReqDetail'
        params = { outReqNo : rowData.reqNo }
      }else if(rowData.inType){
        routerName = 'InReqDetail'
        params = { inReqNo : rowData.reqNo }
      }
      if(!routerName) {
        console.error('router name is empty')
        return false
      }
      this.$router.push({
        name: routerName,
        params: params
      })
    },
    goTrackingList : function (delvStatus, inOutType){
      const vm = this
      this.$router.push({
        name: 'DelvTrackingList',
        params : {
          setGbn : vm.delvType,
          delvStatus : delvStatus,
          inOutType : inOutType,
          searchDate : {
            start : vm.searchDate,
            end : vm.searchDate
          }
        }
      })
    },
    goOutReqList : function (dateType, completeType){
      const vm = this

      let param

      // dateType(당일, 월간누적)에 따라 start 날짜 변동
      let searchDate = {
        start : dateType === vm.constant.month ? vm.searchDateMonthFirstDay : vm.searchDate,
        end : vm.searchDate
      }

      // completeType(신청, 완료)에 따라 검색날짜 변동
      if(completeType === vm.constant.req) {
        param = {
          searchDate : searchDate,
          // 출고신청은 상태값으로 보는게아닌 전체 신청된 개수로 파악
          // outStatus : vm.constant.outStatus.req
        }
      }else if(completeType === vm.constant.complete){
        param = {
          searchCompleteDate : searchDate,
          outStatus : vm.constant.outStatus.complete
        }
      }
      this.$router.push({ name: 'OutReqList',
        params : {
          setGbn : vm.outSetGbnType,
          ...param
        }
      })
    },
    updateLinkLocaCd : function (e){
      const vm = this
      let newLocaCd = vm.$refs.linkLocaCdRef.widget().value()
      if(newLocaCd) {
        vm.$store.dispatch('LINK_LOCA_CHANGE', newLocaCd)
        vm.$refs.updateLinkLocaCdWindowRef.kendoWidget().close()
        vm.allApiCall()
      }
      else {
        kendo.alert("로케이션을 선택해주세요.")
      }
    },
    onChangeMonthYear : function (data){
      const vm = this
      vm.calendarYYYYMM = data.year + (data.month < 10 ? '0' + data.month : data.month + '' )
      vm.getOrdCalender()
    },
    // 발주캘린더
    getOrdCalender: function (){
      const vm = this
      if(vm.selectedCmpyCdList.length === 0){
        vm.calendarAttrs = []
        return []
      }
      let param = {
        searchStartYYYYMMDD : vm.calendarYYYYMM+'01',
        searchEndYYYYMMDD : vm.calendarYYYYMM+'31',
        searchCmpyCd : vm.selectedCmpyCdList,
        searchCalendarGbn : [vm.constant.ordStatus.ordRequired, vm.constant.ordStatus.inComplete, vm.constant.ordStatus.inExpect]
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/ord/calendar`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }

        if(response.data.resultData.length>0){
          vm.calendarAttrs = []
          response.data.resultData.forEach((item,i)=>{
            item.dates = EtnersDateTimeUtil.lookupDateYYYYMMDD(item.dt)
            item.dot = {
              style: {
                'background-color': '#FF8A14',
              },
            }
          })
          vm.calendarAttrs = response.data.resultData.reduce((acc, cur) => {
            let bgColor
            switch (cur.calendarGbn){
              case vm.constant.ordStatus.inComplete :
                bgColor = '#4B79D8'
                break
              case vm.constant.ordStatus.inExpect :
                bgColor = '#FF8A14'
                break
              case vm.constant.ordStatus.ordRequired :
                bgColor = '#FC0000'
                break
            }

            let newItem = {
              dates : EtnersDateTimeUtil.lookupDateYYYYMMDD(cur.dt),
              dot : {
                style : {
                  'background-color': bgColor
                }
              }
            }
            if(cur.field !== 'rowNumber'){ acc.push(newItem) }
            return acc;
          }, []);
        }
      })
    },
    // 입고
    getInCnt: function (){
      const vm = this
      let param = {
        searchYYYYMMDD : EtnersDateTimeUtil.dateToYYYYMMDD(vm.searchDate),
        searchCmpyCd : vm.selectedCmpyCdList
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/dash-board/in`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }

        vm.inCnt = response.data.resultData
      })
    },
    //검품
    getInspCnt: function (){
      const vm = this
      let param = {
        searchYYYYMMDD : EtnersDateTimeUtil.dateToYYYYMMDD(vm.searchDate),
        searchCmpyCd : vm.selectedCmpyCdList
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/dash-board/insp`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.inspCnt = response.data.resultData
      })
    },
    // 공지사항
    getBoardList: function (){
      const vm = this
      let param = {
        searchYYYYMMDD : EtnersDateTimeUtil.dateToYYYYMMDD(vm.searchDate),
        searchCmpyCd : vm.selectedCmpyCdList
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/dash-board/board`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.boardList = response.data.resultData.splice(0, 3)
      })
    },
    // 배송
    getDelvCnt: function (){
      const vm = this
      let param = {
        searchYYYYMMDD : EtnersDateTimeUtil.dateToYYYYMMDD(vm.searchDate),
        searchCmpyCd : vm.selectedCmpyCdList
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/dash-board/delv`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.delvCnt = response.data.resultData
      })
    },
    // 출고현황(그래프)
    getOutCompleteDateCnt: function (){
      const vm = this

      if(!vm.selectedEndDate){
        return
      }

      let param = {
        searchStartYYYYMMDD : EtnersDateTimeUtil.dateToYYYYMMDD(vm.selectedStartDate),
        searchEndYYYYMMDD : EtnersDateTimeUtil.dateToYYYYMMDD(vm.selectedEndDate),
        searchOutType : vm.searchOutType,
        searchCmpyCd : vm.selectedCmpyCdList
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/dash-board/out/complete`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }

        let outComplete = _.cloneDeep(response.data.resultData.outComplete)

        outComplete.forEach((item, i) => {
          let isChangeMonth = false
          if(i === 0 || item.outReqDate.split('/')[1] !== response.data.resultData?.outComplete[i-1]?.outReqDate?.split('/')[1]){
            isChangeMonth = true
          }
          if(isChangeMonth){
            item.category = Number(item.outReqDate.split('/')[1]) + '/' + Number(item.outReqDate.split('/')[2])
          }else {
            item.category = Number(item.outReqDate.split('/')[2])
          }
          item.MMdd = item.outReqDate.split('/')[1] + '/' + item.outReqDate.split('/')[2]
        });

        vm.outChartDataSource = outComplete

        if(outComplete.length > 0){
          // 차트의 구분선을 5개로 변경하기위한 코드
          let maxCnt = outComplete.reduce(function(prev, current) {return (prev.outCnt > current.outCnt) ? prev : current}).outCnt
          let num = 10 ** (maxCnt.toString().length === 1 ? 1 : maxCnt.toString().length - 1)
          // 최대값의 2번째 자릿수에서 올림한 수를 chart의 최대값으로 한다(올림하지 않는경우 최대값과 데이터값이 일치하는 데이터의 UI가 일부 잘림)
          vm.outChartMaxCnt = Math.ceil(maxCnt / num) * num
          // 구분선의 개수를 5로 지정(5칸)
          vm.outChartMajorUnit = vm.outChartMaxCnt / 5
          // 최대값이 5보다 작은 경우 왼쪽 보조값이 0 0 0 1 1건으로 표시되는것을 방지
          if(maxCnt < 5){
            vm.outChartMaxCnt = 5
            vm.outChartMajorUnit = 1
          }
        }

      })
    },
    // 출고
    getOutCnt: function (){
      const vm = this
      let param = {
        searchYYYYMMDD : EtnersDateTimeUtil.dateToYYYYMMDD(vm.searchDate),
        searchCmpyCd : vm.selectedCmpyCdList
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/dash-board/out`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.outCnt = response.data.resultData
      })
    },
    openDelvAbnormalWindow : function (inOutType){
      const vm = this
      vm.$refs.abnormalWindowRef.kendoWidget().center().open()
      vm.getDelvAbnormalList(inOutType)
    },
    convertTwoDigit : function(number) {
      if (number < 10) {
        return `0${number}`
      } else {
        return `${number}`
      }
    },
  },
  data() {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;', }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    let maxDate = 30
    if(new Date().getMonth() === 1) {
      maxDate = 27
    }else if([4,6,9,11].includes(new Date().getMonth()+1)){
      maxDate = 29
    }else {
      maxDate = 30
    }
    return {
      constant : {
        complete : '완료',
        req : '신청',
        expect : '예정',
        new : '신품',
        renew : '재생',
        outComplete : '출고완료',
        outReq : '출고신청',
        day : 'DAY',
        month : 'MONTH',
        outStatus : {
          req : '02',
          complete : '03',
          cancel : '04'
        },
        inStatus : {
          complete : '04'
        },
        renewGbn : {
          new : '01',
          renew : '02'
        },
        ordStatus : {
          inComplete : '04',
          inExpect : '03',
          ordRequired : '01'
        },
        ordStatusCdList : [
          { ordStatus : '01', value : '01', ordStatusNm : '발주필요', color : '#FC0000' },
          { ordStatus : '03', value : '03', ordStatusNm : '입고예정', color : '#FF8A14'},
          { ordStatus : '04', value : '04', ordStatusNm : '입고완료', color : '#4B79D8'},
        ]
      },
      selectedCalendarDate : new Date(),
      calendarAttrs : [],
      calendarYYYYMM : new Date().getFullYear() + new Date().getMonth() +'',

      selectedCmpyCdList : [],
      selectedCmpyCdItemList : [],

      // 출고그래프의 시작날짜/마지막날짜
      selectedStartDate : new Date((new Date).getFullYear(), (new Date).getMonth(), 1),
      selectedEndDate : new Date(),
      maxDate : maxDate,

      // 검색날짜와 검색한 날짜인 달의 1일
      searchDate : new Date(),
      searchDateMonthFirstDay : new Date((new Date).getFullYear(), (new Date).getMonth(), 1),

      // 공지사항
      boardList : [],
      // 배송
      delvType: '01',
      delvTypeMap : {
        '01' : 'delvNew',
        '02' : 'delvRenew',
        '03' : 'delvEtc'
      },
      delvCnt : {
        delvNew : {},
        delvRenew : {},
        delvEtc : {}
      },
      // 검품정보
      inspCnt : {},
      // 입고
      inType: 'inNew',
      inCnt: {
        inNew : {
          expect : 0,
          expectMonth : 0,
          complete : 0,
          completeMonth : 0,
        },
        inReq : {
          expect : 0,
          expectMonth : 0,
          complete : 0,
          completeMonth : 0,
        },
      },
      // 재고
      stockCnt: {
        chart : [],
      },
      // 패킹
      packCnt: {},
      // 출고 꺾은선 그래프
      searchOutType : '',
      outChartDataSource : [],
      outChartMajorUnit : 1,
      outChartMaxCnt : 5,
      // 출고
      outSetGbnType: '01',
      outSetGbnTypeMap : {
        '01' : 'outNew',
        '02' : 'outRenew',
        '03' : 'outEtc'
      },
      outCnt : {
        outNew : {
          reqCnt : 0,
          reqMonthCnt : 0,
          completeCnt : 0,
          completeMonthCnt : 0,
        },
        outRenew : {
          reqCnt : 0,
          reqMonthCnt : 0,
          completeCnt : 0,
          completeMonthCnt : 0,
        },
        outEtc : {
          reqCnt : 0,
          reqMonthCnt : 0,
          completeCnt : 0,
          completeMonthCnt : 0,
        },
      },

      // 차트 색상
      chartColor : [
        '#81ECEC'
        ,'#19ba68'
        ,'#74B9FF'
        ,'#FAB1A0'
        ,'#FF7675'
        ,'#A29BFE'
        ,'#FD79A8'
        ,'#00B894'
        ,'#FDCB6E'
        ,'#00CEC9'
        ,'#E17055'
        ,'#0984E3'
        ,'#D63031'
        ,'#6C5CE7'
        ,'#E84393'
      ],

      delvAbnormalDataSource : [],
      delvAbnormalGridColumns : [
        {
          field: 'rowNumber',
          title: 'No',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          sortable: false
        },
        {
          field: 'inOutTypeNm',
          title: '구분',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'delvStatusNm',
          title: '배송상태',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'wbNo',
          title: '운송장번호',
          width: '15%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'setNm',
          title: '세트명',
          width: '20%',
          headerAttributes: headerAttributes
        },
        {
          field: 'lastMdfyDt',
          title: '최종체류일',
          width: '12%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'stayDuration',
          title: '체류기간(일)',
          width: '10%',
          sortable: false,
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
      ],
      delvAbnormalParams : {},
      isUpdateLinkLocaCdWindowOpen : false,
      isDevlAbnormalWindowOpen : false
    }
  },
}
</script>

<style lang="css" scoped>
.efs_dsb_company{
  border: 1px solid red;
}
</style>
