<template>
  <kendo-multiselect
      ref="cmpyCdRef"
      id="cmpyNm"
      :data-source="cmpyCdTotalDataArray"
      :data-text-field="textField"
      :data-value-field="'cmpyCd'"
      :tag-mode="'single'"
      :value="cmpyCdDataArray"
      :auto-close="false"
      @change="onChangeCmpyCdDropdownList"
      :tag-template="`<span class='k-input'>#:values.length# 회사 선택됨 </span>`"
  >
  </kendo-multiselect>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import Constant from '@/common/constant'

export default {
  name: 'MultiSelectCompany',
  props : {
    propsCmpyCdDataArray : {
      type : Array,
      required : false,
      default : function (){
        return []
      }
    },
    cmpyGbn: {
      type: Array,
      default : function(value) {
          return [Constant.efs.cmpyGbn.CLIENT_COMPANY]
      },
      required: false,
    },
  },
  watch : {
    propsCmpyCdDataArray : function (newValue, oldValue){
      this.$refs.cmpyCdRef.kendoWidget().value(newValue)
    }
  },
  created: function () {
    let vm = this
    vm.getCmpyCdList()
  },
  updated(){
    const vm = this
    if(vm.$props.propsCmpyCdDataArray.length>1){
      vm.$refs.cmpyCdRef.kendoWidget().value(vm.propsCmpyCdDataArray)
    }else {
      vm.$refs.cmpyCdRef.kendoWidget().value(vm.cmpyCdDataArray)
    }
    vm.onChangeCmpyCdDropdownList()
  },
  methods: {
    getCmpyCdList: function () {
      const vm = this
      let param = {
        loginUserToken: sessionStorage.loginUserToken,
        searchCmpyGbn : vm.cmpyGbn,
        requestType : 'EXPORT',
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/drop-down/company`, param)
      .then((response) => {
        if (
            response.data === undefined ||
            response.data.resultStatus === undefined
        ) {
          kendo.alert("데이터가 정상적으로 조회되지 않았습니다.");
          return false;
        }
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.cmpyCdTotalDataArray = response.data.resultData
        vm.cmpyCdDataArray = _.cloneDeep(response.data.resultData)
      })
      .catch((error) => {
        console.log(error);
      });
    },
    onChangeCmpyCdDropdownList () {
      const vm = this
      let data = {
        cmpyCd: vm.$refs.cmpyCdRef.kendoWidget().value(),
        cmpyArray: vm.$refs.cmpyCdRef.kendoWidget().dataItems(),
      }
      vm.$emit('on-change-multi-dropdown-company', data)
    },
  },
  data () {
    return {
      textField: 'cmpyNm',
      cmpyCdTotalDataArray: [],
      cmpyCdDataArray: [],
      bizType: '',
      bizSubType: '',
    }
  },
}
</script>

<style scoped>
#cmpyNm_taglist{
  width:100%;
  align-items:center;
}
#cmpyNm_taglist li{
  margin:0 0 0 0px;
}
</style>
